import { gql, useQuery } from "@apollo/client";

const STATION = gql`
  query Station($slug: String!) {
    station(slug: $slug) {
      id
      slug
      name
    }
  }
`;

export interface IStation {
  id: number;
  name: string;
  slug: string;
}

export function useStation(slug: string): IStation | undefined {
  const { error, loading, data } = useQuery(STATION, { variables: { slug } });

  if (error || loading) {
    return undefined;
  }

  return {
    id: data.station.id,
    name: data.station.name,
    slug: data.station.slug,
  };
}
