import { AnimatePresence, motion } from "framer-motion";
import { MouseEventHandler, useContext, useState } from "react";
import { IoLocate, IoSwapHorizontal } from "react-icons/io5";
import styled from "styled-components";
import { AppContext } from "..";
import { Page } from "../components/Page";
import { SmartSearch } from "../components/SmartSearch";
import { StatusBar } from "../components/StatusBar";
import { TabBar } from "../components/TabBar";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/Button";

const FIND_BY_LOCATION = gql`
  query FindByLocation($latitude: Float!, $longitude: Float!) {
    findByLocation(latitude: $latitude, longitude: $longitude) {
      name
      id
      slug
    }
  }
`;

export const Search = () => {
  let navigate = useNavigate();
  const { client, origin, destination, setOrigin, setDestination } = useContext(AppContext);

  const [search, setSearch] = useState(false);
  const [option, setOption] = useState<"origin" | "destination" | null>(null);

  const handleOptionsButton: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (origin && destination) {
      let original = { origin, destination };
      setOrigin(original.destination);
      setDestination(original.origin);
    } else {
      console.log("locate");
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const result = await client.query({
            query: FIND_BY_LOCATION,
            variables: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          });
          setOrigin({
            id: result.data.findByLocation.id,
            name: result.data.findByLocation.name,
            slug: result.data.findByLocation.slug,
          });
        },
        console.error,
        { enableHighAccuracy: true }
      );
    }
  };

  return (
    <>
      <StatusBar background="#11131a" />

      <AnimatePresence>
        {search ? (
          <SmartSearch
            onClose={() => setSearch(false)}
            onSelect={(station: any) => {
              setSearch(false);
              if (option === "origin") setOrigin(station);
              else if (option === "destination") setDestination(station);
            }}
          />
        ) : null}
      </AnimatePresence>

      <Page background="#11131a">
        <h1 style={{ marginLeft: "4px" }}>
          Find a <span style={{ color: "#9eebdf" }}>train</span>
        </h1>

        <Options>
          <Option
            onClick={() => {
              setSearch(true);
              setOption("origin");
            }}
          >
            <OptionHeader big={!origin}>From</OptionHeader>
            <OptionContent>{origin && origin.name}</OptionContent>
          </Option>
          <OptionDivider />
          <Option
            onClick={() => {
              setSearch(true);
              setOption("destination");
            }}
          >
            <OptionHeader big={!destination}>To</OptionHeader>
            <OptionContent>{destination && destination.name}</OptionContent>
          </Option>
          <OptionsButton whileTap={{ scale: 0.9 }} onClick={handleOptionsButton}>
            {origin && destination ? (
              <IoSwapHorizontal size="24px" color="#000000" />
            ) : (
              <IoLocate size="24px" color="#000000" />
            )}
          </OptionsButton>
        </Options>
        <Button
          onClick={() => {
            if (origin && destination) {
              navigate(`/connections/${origin.slug}/${destination.slug}`);
            }
          }}
        >
          Search
        </Button>
      </Page>
      <TabBar currentPage="search" />
    </>
  );
};

const Options = styled.div`
  width: 100%;
  height: 160px;
  background: #0c0e14;
  margin-top: 20px;
  border-radius: 20px;
`;

const OptionsButton = styled(motion.div)`
  width: 24px;
  height: 24px;
  padding: 8px;
  position: relative;
  top: -100px;
  left: calc(100% - 68px);
  background: #9eebdf;
  border-radius: 10px;
`;

const Option = styled.div`
  padding-top: 18px;
  margin-left: 30px;
  width: calc(100% - 60px);
  height: 61px;
  vertical-align: center;
`;

const OptionDivider = styled.div`
  margin-left: 30px;
  width: calc(100% - 60px);
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
`;

const OptionHeader = styled.p<{ big?: boolean }>`
  margin-top: ${({ big }) => (big ? "14px" : "0")};
  font-size: ${({ big }) => (big ? "18px" : "14px")};
  font-weight: 500;
  color: #86858a;
`;

const OptionContent = styled.p`
  font-size: 18px;
  font-weight: bold;
`;
