import dayjs from "dayjs";
import { useState } from "react";
import { HiArrowSmRight } from "react-icons/hi";
import { IoIosArrowBack } from "react-icons/io";
import { IoHeartCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Connections } from "../components/Connections";
import { Navbar, NavbarIcon, NavbarSubtitle, NavbarTitle } from "../components/Navbar";
import { Page } from "../components/Page";
import { StatusBar } from "../components/StatusBar";
import { TabBar } from "../components/TabBar";
import { useFavorites } from "../hooks/useFavorites";
import { useStation } from "../hooks/useStation";

export const ConnectionsView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const origin = useStation(params.origin!);
  const destination = useStation(params.destination!);

  const favorite = {
    from: origin!,
    to: destination!,
  };

  const { addFavorite, removeFavorite, isFavorite } = useFavorites();

  const handleLike = () => {
    if (!(origin && destination)) {
      return;
    }
    if (isFavorite(favorite)) {
      removeFavorite(favorite);
    } else {
      addFavorite(favorite);
    }
  };

  return (
    <>
      <StatusBar background="#0c0e14" />
      <Page background="#11131a" style={{ marginTop: "100px", height: "calc(100vh - 234px)" }}>
        {origin && destination ? (
          <Connections
            date={date}
            from={origin.slug}
            to={destination.slug}
            spinnerStyle={{
              position: "absolute",
              left: "calc(50vw - 22px)",
              top: "calc(50vh - 44px)",
            }}
          />
        ) : null}
      </Page>
      <Navbar background="#0c0e14">
        <NavbarIcon whileTap={{ scale: 0.9 }} position="left" onClick={() => navigate(-1)}>
          <IoIosArrowBack size="24px" style={{ position: "relative", left: "-2px" }} />
        </NavbarIcon>
        <NavbarTitle>
          <p style={{ color: "#b8b8bf" }}>{dayjs(date).format("dddd HH:mm")}</p>
          <p style={{ color: "#9eebdf" }}>{dayjs(date).format("MMMM D, YYYY")}</p>
        </NavbarTitle>
        <NavbarDatetimeInput
          type="datetime-local"
          value={`${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(2, "0")}-${`${date.getDate()}`.padStart(
            2,
            "0"
          )}T${`${date.getHours()}`.padStart(2, "0")}:${`${date.getMinutes()}`.padStart(2, "0")}`}
          onChange={(event: any) => setDate(new Date(event.target.value))}
        />
        <NavbarSubtitle>
          {origin && destination ? (
            <>
              <span>{origin.name}</span>
              <HiArrowSmRight
                fill="#9eebdf"
                size="24px"
                style={{ padding: "0 10px", position: "relative", top: "6px" }}
              />
              <span>{destination.name}</span>
            </>
          ) : null}
        </NavbarSubtitle>
        <NavbarIcon onClick={handleLike} whileTap={{ scale: 0.9 }} position="right">
          <div
            style={{
              position: "relative",
              width: "24px",
              height: "24px",
              borderRadius: "100%",
              background: "#fff",
            }}
          />
          <IoHeartCircle
            size="36px"
            fill={origin && destination && isFavorite(favorite) ? "#ff6584" : "#333"}
            style={{ position: "relative", left: "-6px", top: "-30px", transition: "fill 200ms" }}
          />
        </NavbarIcon>
      </Navbar>
      <TabBar currentPage="search" />
    </>
  );
};

export const NavbarDatetimeInput = styled.input`
  position: absolute;
  top: 8px;
  left: 100px;
  width: calc(100vw - 200px);
  height: 44px;
  border: 0;
  opacity: 0;
`;
