// TODO: rewrite this component
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { IoMdTime } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { useQuery, gql } from "@apollo/client";
import { useLocalStorage } from "../hooks/useLocalStorage";

const SEARCH = gql`
  query Search($query: String!) {
    search(query: $query) {
      name
      id
      slug
    }
  }
`;

export const SmartSearch = ({ onSelect, onClose }: any) => {
  const input = useRef<HTMLInputElement | null>(null);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selected, setSelected] = useState(false);
  const [closed, setClosed] = useState(false);
  const [recentSearches, setRecentSearches] = useLocalStorage<any[]>(
    "recentSearches",
    []
  );

  const { refetch } = useQuery(SEARCH, {
    variables: { query: input.current?.value || "" },
  });

  useEffect(() => {
    input.current?.focus();
  }, [input]);

  const select = (station: any) => {
    if (!selected) {
      if (recentSearches) {
        setRecentSearches([
          station,
          ...recentSearches.filter((search) => search.id !== station.id),
        ]);
      }

      onSelect({
        name: station.name,
        slug: station.slug,
        id: station.id,
      });
      setSelected(true);
    }
  };

  return (
    <Main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 25 }}
    >
      <Input
        autoCorrect="off"
        autoComplete="off"
        spellCheck={false}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        exit={{ y: -100 }}
        transition={{ type: "spring", stiffness: 300, damping: 25 }}
        ref={input}
        autoFocus
        tabIndex={-1}
        type="text"
        placeholder="Search for a station"
        onChange={() => {
          if (input.current) {
            refetch().then((res) => setSearchResults(res.data.search));
          }
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter" && onSelect) {
            input.current?.blur();
            if (searchResults.length !== 0) {
              select(searchResults[0]);
            }
          }
        }}
      />
      <CloseButton
        onClick={(e: any) => {
          setClosed(true);
          e.stopPropagation();
          onClose();
        }}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        exit={{ y: -100 }}
        transition={{ type: "spring", stiffness: 300, damping: 25 }}
      />
      <SearchResults
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: "spring", stiffness: 150, damping: 25 }}
      >
        {(!input.current?.value && recentSearches) ||
        (input.current?.value === "" && recentSearches)
          ? recentSearches.map((station: any, i: number) => (
              <div key={station.id}>
                <Station onClick={() => (closed ? null : select(station))}>
                  <div>
                    <IoMdTime
                      style={{
                        position: "relative",
                        top: "2px",
                        opacity: 0.5,
                        marginRight: "8px",
                      }}
                    />
                    {station.name}
                  </div>
                  <IoCloseOutline
                    size="20px"
                    style={{ opacity: 0.5, padding: "15px" }}
                    onClick={(e: any) => {
                      if (closed) {
                        return;
                      }
                      e.stopPropagation();
                      setRecentSearches(
                        recentSearches.filter(
                          (search: any) => search.id !== station.id
                        )
                      );
                    }}
                  />
                </Station>
                {i !== recentSearches.length - 1 && <Divider />}
              </div>
            ))
          : null}
        {input.current?.value !== "" &&
          searchResults.map((station, i) => (
            <div key={station.id}>
              <Station onClick={() => (closed ? null : select(station))}>
                {station.name}
              </Station>
              {i !== searchResults.length - 1 && <Divider />}
            </div>
          ))}
      </SearchResults>
    </Main>
  );
};

const CloseButton = styled(motion.div)`
  z-index: 52;
  position: absolute;
  right: 25px;
  top: 25px;
  &::before {
    content: "Close";
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 15px;
    color: #888;
  }
`;

const Divider = styled.div`
  margin-left: 15px;
  width: calc(100% - 30px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  z-index: 52;
`;

const Station = styled.div`
  color: #fff !important;
  background: #0c0e14;
  display: grid;
  grid-template-columns: 1fr 30px;
  width: calc(100vw - 80px);
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  color: white;
  font-size: 16px;
  z-index: 51;
  transition: background 250ms;
`;

const Input = styled(motion.input)`
  position: absolute;
  outline: none;
  border: 0;
  border-radius: 15px !important;
  left: 25px;
  top: 25px;
  width: calc(100vw - 130px);
  padding: 0 65px 0 15px !important;
  height: 50px !important;
  color: #fff;
  font-size: 16px !important;
  background-color: #0c0e14 !important;
  transition: background 250ms;
  z-index: 51;
  box-shadow: 0 6px 10px rgb(0 0 0 / 5%);
  touch-action: none;
  user-select: text;
  -webkit-user-select: text;
`;

const SearchResults = styled(motion.div)`
  box-shadow: 0 6px 10px rgb(0 0 0 / 5%);
  position: absolute;
  top: 100px;
  max-height: calc(100vh - 202px);
  left: 25px;
  margin-right: 25px;
  width: calc(100vw - 50px);
  border-radius: 15px;
  z-index: 52;
  background-color: #0c0e14;
  transition: background 250ms;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

const Main = styled(motion.div)`
  background: #11131a;
  transition: background 250ms;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  overflow: hidden;
`;
