import { useQuery, gql } from "@apollo/client";
import TailSpin from "react-loading-icons/dist/components/tail-spin";
import { Connection, IConnection } from "./Connection";

const CONNECTIONS = gql`
  query Connections($from: String!, $to: String!, $date: DateTime!) {
    connections(from: $from, to: $to, date: $date) {
      departure {
        station
        date
        delay
      }
      arrival {
        station
        date
        delay
      }
      changes
      brands
    }
  }
`;

export const Connections = ({
  from,
  to,
  date,
  spinnerStyle,
}: {
  from: string;
  to: string;
  date: Date;
  spinnerStyle?: any;
}) => {
  const { loading, error, data } = useQuery(CONNECTIONS, {
    variables: { from, to, date },
    pollInterval: 60000,
  });

  if (loading) {
    return <TailSpin stroke="#9eebdf" style={spinnerStyle} />;
  }

  if (error) {
    return null;
  }

  return data.connections.map((connection: IConnection) => (
    <Connection key={JSON.stringify(connection)} connection={connection} />
  ));
};
