import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Navbar = styled.div<{ background: string; height?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  background: ${({ background }) => background};
  height: ${({ height }) => (height ? height : "100px")};
  border-bottom: 1px solid #ffffff0d;
`;

export const NavbarTitle = styled.div`
  position: absolute;
  top: 8px;
  left: 100px;
  width: calc(100vw - 200px);
  height: 44px;
  text-align: center;
  p {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const NavbarSubtitle = styled.div`
  position: absolute;
  top: 56px;
  left: 28px;
  width: calc(100vw - 56px);
  height: 40px;
  text-align: center;
  span {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const NavbarIcon = styled(motion.div)<{ position: "left" | "right" }>`
  position: absolute;
  top: 8px;
  ${({ position }) =>
    position === "left"
      ? css`
          left: 28px;
        `
      : css`
          right: 28px;
        `}
  background: #1f202c;
  width: 24px;
  height: 24px;
  padding: 10px;
  border-radius: 100%;
`;
