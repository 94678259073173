import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./views/Home";
import { Search } from "./views/Search";
import { ApolloClient, InMemoryCache, ApolloProvider, NormalizedCacheObject } from "@apollo/client";

import "./index.css";
import { ConnectionsView } from "./views/Connections";
import { LocalStorageWrapper, persistCache } from "apollo3-cache-persist";
import { IStation } from "./hooks/useStation";

export const AppContext = createContext({
  client: undefined as any as ApolloClient<NormalizedCacheObject>,
  origin: null as any as IStation,
  setOrigin: (value: IStation) => {},
  destination: null as any as IStation,
  setDestination: (value: IStation) => {},
});

const App = () => {
  const [origin, setOrigin] = useState<any>(null);
  const [destination, setDestination] = useState<any>(null);

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache();
      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      });
      setClient(
        new ApolloClient({
          uri: "https://railhub-api.herokuapp.com",
          cache,
        })
      );
    }

    init().catch(console.error);
  }, []);

  if (!client) {
    // TODO
    return null;
  }

  return (
    <AppContext.Provider value={{ client, origin, setOrigin, destination, setDestination }}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<Search />} />
            <Route path="/connections/:origin/:destination" element={<ConnectionsView />} />
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </AppContext.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
