import { useLocalStorage } from "./useLocalStorage";
import { IStation } from "./useStation";

export interface IFavorite {
  from: IStation;
  to: IStation;
}

const equals = (a: IFavorite, b: IFavorite) => a.from.id === b.from.id && a.to.id === b.to.id;

export function useFavorites() {
  const [favorites, setFavorites] = useLocalStorage<IFavorite[]>(`favorites`, []);

  return {
    favorites,
    isFavorite: (favorite: IFavorite) => {
      return favorites.find(e => equals(e, favorite)) !== undefined;
    },
    addFavorite: (favorite: IFavorite) => {
      setFavorites([favorite, ...favorites]);
    },
    removeFavorite: (favorite: IFavorite) => {
      setFavorites(favorites.filter(e => !equals(e, favorite)));
    }
  }
}
