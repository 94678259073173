import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Connections } from "../components/Connections";
import { Page } from "../components/Page";
import { StatusBar } from "../components/StatusBar";
import { TabBar } from "../components/TabBar";
import { useFavorites } from "../hooks/useFavorites";

export const Home = () => {
  const { favorites } = useFavorites();
  const [date] = useState(new Date());

  return (
    <>
      <StatusBar background="#11131a" />
      <Page background="#11131a">
        <h1 style={{ marginBottom: "40px" }}>My trains</h1>
        {favorites.map((favorite) => (
          <div key={`${favorite.from.id} - ${favorite.to.id}`}>
            <h2 style={{ margin: "20px 0 10px 5px", fontSize: "16px", fontWeight: 500 }}>
              <Link to={`/connections/${favorite.from.slug}/${favorite.to.slug}`} style={{ color: "#9eebdf" }}>
                {favorite.from.name} – {favorite.to.name}
              </Link>
            </h2>
            <div
              style={{
                overflow: "hidden",
                width: "100%",
                height: "182px",
              }}
            >
              <Swipeable>
                <Connections
                  from={favorite.from.slug}
                  to={favorite.to.slug}
                  date={date}
                  spinnerStyle={{
                    position: "relative",
                    top: "58px",
                    left: "calc(50vw - 48px)",
                  }}
                />
              </Swipeable>
            </div>
          </div>
        ))}
      </Page>
      <TabBar currentPage="home" />
    </>
  );
};

const Swipeable = styled.div`
  display: flex;
  gap: 10px;
  height: 182px;
  overflow: scroll hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
`;
