import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { IoIosSwap, IoIosTimer } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import styled from "styled-components";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export interface IConnection {
  departure: {
    station: number;
    date: Date;
    delay: number;
  };
  arrival: {
    station: number;
    date: Date;
    delay: number;
  };
  changes: number;
  brands: string[];
}

export const Connection = ({ connection }: { connection: IConnection }) => {
  connection.departure.date = new Date(new Date(connection.departure.date).getTime() + 60 * 1000 * new Date().getTimezoneOffset());
  connection.arrival.date = new Date(new Date(connection.arrival.date).getTime() + 60 * 1000 * new Date().getTimezoneOffset());
  
  return (
    <Card>
      <Grid columns="1fr 1fr" style={{ margin: "0 10px" }}>
        <Departure>
          {dayjs
            .duration(dayjs(connection.departure.date).add(connection.departure.delay, "min").diff())
            .humanize(true)}
        </Departure>
        <div style={{ marginLeft: "auto", display: "flex" }}>
          {connection.brands.map((brand, i) => (
            <Brand brand={brand} key={brand + i}>
              {brand.replaceAll("SKMT", "SKM")}
            </Brand>
          ))}
        </div>
      </Grid>
      <Grid columns="1fr 1fr" style={{ marginTop: "16px", padding: "0 10px" }}>
        <Column>
          <Time>{dayjs(connection.departure.date).format("HH:mm")}</Time>
          <Delay style={{ opacity: connection.departure.delay ? 1 : 0 }}>+{connection.departure.delay} min</Delay>
        </Column>
        <Column>
          <Time>{dayjs(connection.arrival.date).format("HH:mm")}</Time>
          <Delay style={{ opacity: connection.departure.delay ? 1 : 0 }}>+{connection.arrival.delay} min</Delay>
        </Column>
      </Grid>

      <Grid style={{ marginTop: "10px" }} columns="1fr 1fr 1fr">
        <Column>
          <IoIosTimer size="18px" />
          <h4 style={{ fontSize: "14px" }}>
            {dayjs
              .duration(dayjs(connection.arrival.date).diff(connection.departure.date))
              .humanize()
              .replaceAll("minutes", "mins")}
          </h4>
        </Column>
        <Column>
          <IoIosSwap size="18px" />
          <h4 style={{ fontSize: "14px" }}>{connection.changes}</h4>
        </Column>
        <Column>
          <IoLocationOutline size="18px" />
          <h4 style={{ fontSize: "14px" }}>
            {dayjs.duration(dayjs(connection.arrival.date).diff(connection.departure.date)).minutes()} km
          </h4>
        </Column>
      </Grid>
    </Card>
  );
};

const Card = styled.div`
  scroll-snap-align: start;
  flex: none;
  max-width: 400px;
  width: calc(100% - 20px);
  height: 140px;
  background: #0c0e14;
  border: 1px solid #ffffff0a;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Time = styled.h1`
  font-weight: bold;
  font-size: 24px;
`;

const Brand = styled.div<{ brand: string }>`
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  margin: 0 2px;
  padding: 0 8px;
  height: 18px;
  background: ${({ brand }) =>
    brand === "PR" ? "#ff65849f" : brand === "IC" ? "#65b5ff9f" : brand === "SKMT" ? "#ffc1659f" : "#7f7f7f9f"};
  border-radius: 6px;
`;

const Delay = styled.p`
  color: #ff6584;
  font-weight: 500;
  font-size: 14px;
`;

const Departure = styled.p`
  margin: 0;
  color: #b8b8bf;
  font-size: 14px;
  font-weight: 500;
`;

const Grid = styled.div<{ columns?: string }>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "none"};
`;

const Column = styled.div`
  text-align: center;
`;
