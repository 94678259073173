import styled from "styled-components";

export const Button = ({
  onClick,
  children,
}: {
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
  children: any;
}) => {
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
};

export const StyledButton = styled.div`
  border: none;
  width: 100%;
  height: 60px;
  background: #0c0e14;
  margin-top: 20px;
  border-radius: 20px;
  line-height: 60px;
  text-align: center;
  color: #9eebdf;
  font-size: 18px;
  font-weight: 600;
`;
