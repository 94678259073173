import styled from "styled-components";
import { BsGridFill } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";

export const TabBar = ({ currentPage }: { currentPage: string }) => {
  return (
    <Container>
      <Link to="/" style={{ padding: "10px", margin: "0 auto" }}>
        <BsGridFill fill={currentPage === "home" ? "#47cfbb" : "#fff"} size={24} />
      </Link>
      <Link to="/search" style={{ padding: "10px", margin: "0 auto" }}>
        <IoSearch fill={currentPage === "search" ? "#47cfbb" : "#fff"} size={24} />
      </Link>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  left: 0;
  bottom: 0;
  width: calc(100vw - 40px);
  height: 45px;
  padding: 5px 20px 30px 20px;
  background: #11131a;
  border-top: 1px solid #ffffff0d;
`;
