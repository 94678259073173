import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

export const Page = ({ background, style, children }: { children: any; background?: string; style?: any }) => {
  let ref = useRef<HTMLDivElement | null>(null);
  let [iv, setIv] = useState<any>(null);

  useEffect(() => {
    setIv(
      setInterval(() => {
        if (!ref.current) {
          return;
        }
        if (ref.current.scrollTop === 0) {
          ref.current.scroll(0, 1);
        }
        if (ref.current.scrollTop === ref.current.scrollHeight - ref.current.clientHeight) {
          ref.current.scroll(0, ref.current.scrollHeight - ref.current.clientHeight - 1);
        }
      }, 250)
    );

    return () => clearInterval(iv);
    // eslint-disable-next-line
  }, []);

  return (
    <StyledPage ref={ref} background={background} style={style}>
      {children}
    </StyledPage>
  );
};

const StyledPage = styled.div<{ background?: string }>`
  padding: 28px;
  height: calc(100vh - 100px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ${({ background }) =>
    background
      ? css`
          background: ${background};
        `
      : null}
`;
